
import { Component, Vue } from "vue-property-decorator";
import { Toast,Dialog } from "vant";
import globalConfig from "src/config/index";
import familylist from "src/components/family-list/family-list.vue"
import { ReportService } from "src/services";
import router from "src/router";

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component({
    components:
    {
        familylist
    }
})
export default class OfteUser extends Vue
{
    /**
     * 报告列表
     * @private
     * @returns any
     */
     private dataList: String = "";

    /**
     * 报告Id
     * @private
     * @returns any
     */
    private reportId: any = null;


    /**
     * 报告名称
     * @private
     * @returns any
     */
     private reportName: String = "";

     /**
     * 报告数量
     * @private
     * @returns any
     */
     private selfTotalReportNum = 0;


    /**
     * 当前用户
     * @private
     * @returns any
     */
     private currentUser = {
        age: 0,
        avatar: null,
        employerMemberId: null,
        familyType: 2,
        familyTypeStr: "",
        isRed: false,
        marriageStatus: 0,
        marriageStatusStr: "",
        memberId: null,
        memberName: "",
        memberType: 2,
        memberTypeStr: "",
        phone: "",
        sex: 1,
        sexStr: ""
      };


    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
     protected created()
    {
        this.getReportList();
    }

     /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
     protected get id(): any
    {
        return this.$route.query && (this.$route.query.id)
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    

    /**
     * 报告列表
     * @private
     * @returns void
     */
     private async getReportList(): Promise<void>
    {
        try
        {
            let {content:result} = await ReportService.instance.getPhysicalReportList({employerMemberId: this.id});

                this.selfTotalReportNum = result.data.selfTotalReportNum || 0;
                this.dataList = result.data.reportGroups
                this.reportId = this.$route.query.reportId as any
        }
        catch(err)
        {
            Toast(err);
        }
    }

    private async chooseReport(): Promise<void>
    {
        try
        {
          this.$router.replace({name:"relatedReport",query:{name:this.reportName as any,id:this.reportId as any}})
          this.$router.go(-1)
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 返回体检报告id
     * @private
     * @returns void
     */
     private async examinationReport(item): Promise<void>
    {
        try
        {
            this.reportId = item.id
            this.reportName = item.reportName
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
     protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 跳转报告上传
     * @private
     * @returns void
     */
     private async onActionUpLoad(id): Promise<void>
    {
        try
        {
            console.log(this.userInfo,);
            
            let selectUser 
            let user = JSON.parse(localStorage.getItem("selectUser"))
            if(user) {
              selectUser = JSON.parse(localStorage.getItem("selectUser"))
              this.$router.push({name:'reportAppointmentUpload',query:{id:selectUser.employerMemberId,name:selectUser.memberName}})

            } else {
              selectUser = this.userInfo
              this.$router.push({name:'reportAppointmentUpload',query:{id:selectUser.userId,name:selectUser.userName}})
            }
            
        }
        catch(err)
        {
            Toast(err);
        }
    }



}
