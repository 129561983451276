
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Toast,Dialog } from "vant";
import globalConfig from "src/config/index";
import udialog from "src/components/dialog";
import { PackageService, ReportService } from "src/services";

@Component({
    components:{
        udialog
    }
})
export default class OfteUser extends Vue
{

    /**
     * 是否显示
     * @private
     * @returns string
     */
     @Prop({type: Object})
    private analyzeResult: any;


    /**
     * 异常数量
     * @private
     * @returns string
     */
     @Prop({type: Object})
    private humanDataCount: any;


    /**
     * 异常数据
     * @private
     * @returns string
     */
     @Prop({type: Object})
    private humanData: any;

    /**
     * 异常指标
     * @private
     * @returns string
     */
     @Prop({type: Array})
    private abnormalList: any;

    /**
     * 异常指标名称
     * @private
     * @returns string
     */
     @Prop({type: String})
    private bodySystemName: any;

    /**
   * 是否高亮
   * @private
   * @returns any
   */
   @Prop({})
    private highlight: any;

    /**
     * 是否显示弹框
     * @private
     * @returns boolean
     */
     private isShowDialog: boolean = false;

     /**
     * 弹框名称
     * @private
     * @returns boolean
     */
     private dialogName: string = "";

     private packageId: any = null;

     /**
     * 弹框内容
     * @private
     * @returns boolean
     */
     private dialogContent: string = "";

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 显示提示
     * @private
     * @returns void
     */
     private showTip(remark: string,name:string): void
    {
        if(!remark) {
            return
        }
        this.dialogName = name
        this.dialogContent = remark
        this.isShowDialog = true
    }

   /**
   * 是否隐藏
   * @private
   * @returns any
   */
   private isFload = false


   /**
   * 小手
   * @private
   * @returns any
   */
   private showFlag = null

   
   /**
   * 切换图标展示
   * @private
   * @returns any
   */
   protected systemChange(val,click)
    {
      this.$emit("systemChange", val,click);
   }

   /**
   * 更多历史预测
   * @private
   * @returns any
   */
   protected viewMore()
    {
        this.$router.push({name: "historylist"});
    }
 

   /**
   * 跳转上传报告
   * @private
   * @returns any
   */
   protected uploadNewReport() {
      this.$router.push({name:'reportAppointmentUpload',query:{id:this.analyzeResult.memberId,name:this.analyzeResult.memberName}})
   }

   /**
   * 再次定制
   * @private
   * @returns any
   */
   protected async customizeAgain() {
        try {
         let {content:result} = await ReportService.instance.checkQuestion()  
         this.$router.push({name:"relatedReport"})

        } catch (error) {
            Toast(error)
        }
   }
   
    /**
     * 受检人管理-是否可编辑信息
     * @private
     * @returns void
     */
     private get isEditStatus(): any
    {
        return this.$store.getters.getIsEditStatus || true;
    }

    /**
     * 是否展示预约须知
     * @private
     * @returns void
     */
     private get isShowNotice(): any
    {
        return this.$store.getters.getIsShowNotice || false;
    }

    /**
     * 婚姻状态map
     * @private
     * @returns string
     */
     private marriageStatusMap: any = {
        marriaged: 0,
        unMarriage: 1,
    };

    /**
     * 性别map
     * @private
     * @returns string
     */
    private sexMap: any = {
        women: 0,
        men: 1,
    };

    /**
     * 查看定制套餐
     * @private
     * @returns any
     */
    protected async viewCuspackage() {
        let {gender, marriageStatus} = this.analyzeResult;
        if (this.isEditStatus && this.isShowNotice && gender == this.sexMap.women && marriageStatus == this.marriageStatusMap.unMarriage) {
            // 添加检前须知页
            this.$router.push({ name: "physical-inspection", query: { employerMemberId: this.analyzeResult.employerMemberId, questionnaireRId: this.analyzeResult.id }});
        }
        else {
            await this.singlePackage()
            if(this.packageId) {
                this.$router.push({name:'orgList',query:{packageId:this.packageId}})
            } else {
                this.$router.push({name:'cuspackage',query:{employerMemberId:this.analyzeResult.employerMemberId,questionnaireRId:this.analyzeResult.id}})
                
            }
            localStorage.setItem("selectUser",JSON.stringify(this.analyzeResult))
        }
   }

   /**
     * 是否展示套餐列表
     * @private
     * @returns Promise<void>
     */
     private async singlePackage(): Promise<void>
    {
        try
        {
            let {content: result} =  await PackageService.instance.singlePackage(this.analyzeResult.employerMemberId);
            this.packageId = result.data
        }
        catch(err)
        {
            Toast(err);
        }
    }

   /**
   * 动态展示背景样式
   * @private
   * @returns any
   */
   protected portrait(val,click)
    {
        if(this.analyzeResult.gender as any == 1) {
        switch (this.highlight) {
          case 1:
            return 'system1'
          case 2:
            return 'system2'
          case 3:
            return 'system3'
          case 4:
            return 'system4'
          case 5:
            return 'system5'
          case 6:
            return 'system6'
          case 7:
            return 'system7'
          case 8:
            return 'system8'
          case 9:
            return 'system9' 
          default:
            break;
        } 
      } else {
        switch (this.highlight) {
          case 1:
            return 'nvSystem1'
          case 2:
            return 'nvSystem2'
          case 3:
            return 'nvSystem3'
          case 4:
            return 'nvSystem4'
          case 5:
            return 'nvSystem5'
          case 6:
            return 'nvSystem6'
          case 7:
            return 'nvSystem7'
          case 8:
            return 'nvSystem8'
          case 9:
            return 'nvSystem9' 
          default:
            break;
        } 
      }
   }

   /**
   * 动态展示背景样式
   * @private
   * @returns any
   */
   protected fload() {
      //改变isFload的状态
      this.isFload = !this.isFload;
   }

}
