
import { Component, Vue } from "vue-property-decorator";
import { Toast,Dialog } from "vant";
import globalConfig from "src/config/index";
import { ReportService } from "src/services";

@Component
export default class OfteUser extends Vue
{

    /**
     * 获取历史记录
     * @private
     * @returns void
     */
    protected historyList = []

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
     protected created()
    {
        this.gethistoryList();
    }

    /**
     * 获取历史预测
     * @protected
     * @returns void
     */
    protected async gethistoryList  () {
        try {
           let {content:result} = await ReportService.instance.getQuestionlist()
            this.historyList = result.data

        } catch (error) {
            
        }
    }

    

    /**
     * 查看当前的检测记录
     * @protected
     * @returns void
     */
     protected async viewCurrentRecord  (item) {
        localStorage.setItem("selectUser",JSON.stringify(item))
        this.$router.push({name: "aicustomized",query:{id:item.id}});
    }
}
