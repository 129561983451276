
import { Component, Vue } from "vue-property-decorator";
import { Toast,Dialog } from "vant";
import globalConfig from "src/config/index";
import { ReportService } from "src/services";

@Component
export default class OfteUser extends Vue
{
  /**
   * 选择人员
   * @private
   * @returns any
   */
   private userAddress = ""

   /**
   * 当前页
   * @private
   * @returns any
   */
   private page = 0


   /**
   * loading
   * @private
   * @returns any
   */
   private loading = false


   /**
   * 问卷列表
   * @private
   * @returns any
   */
   private questionList = {
    id: 0,
    recordId: 0,
    name: "",
    totalNo: 0,
    answeringNo: 0,
    questionnaireType: 1,
    questionnaireTypeStr: "",
    subItems: []
   }


   /**
   * 问卷调查总数据
   * @private
   * @returns any
   */
   private questionData = {
      id:0,
      employerMemberId:"",
      reportId:"",
      isFinishing:false,
      answeringNo:1,
      questionnaire:{},  // 问卷列表
   }


    /**
     * 报告id
     * @private
     * @returns string
     */
     private get reportId(): string
    {
        return this.$route.query && (this.$route.query.reportId as string) || "";
    }

    /**
     * 当前人的id
     * @private
     * @returns string
     */
     private get employerMemberId(): string
    {
        return this.$route.query && (this.$route.query.employerMemberId as string) || "";
    }


   /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
     protected created()
    {
        this.getQuestionList();
    }

    /**
     * 问卷是否是进行中
     * @private
     * @returns string
     */
     private get progress(): string
    {
        return this.$route.query && (this.$route.query.progress as string) || "";
    }


    /**
     * 问卷列表
     * @private
     * @returns void
     */
     private async getQuestionList(): Promise<void>
    {
        try
        {
            if(this.progress) {
                let {content:result} = await ReportService.instance.continueAnswering();
                this.questionData.employerMemberId = result.data.employerMemberId
                this.questionData.reportId = result.data.reportId
                // 问卷数据
                this.questionList = result.data
                this.questionData.questionnaire = result.data
                this.page = result.data.answeringNo - 1

            } else {
                let {content:result} = await ReportService.instance.getNewestQuestion(1,this.$route.query.employerMemberId,this.$route.query.reportId);
                this.questionData.employerMemberId = this.$route.query.employerMemberId as any
                this.questionData.reportId = this.$route.query.reportId as any
                this.questionList = result.data
                this.questionData.questionnaire = result.data
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 
     * 上一页
     * @private
     * @returns void
     */
     private async prevPage(): Promise<void>
    {
        if(this.page == 0) {
          Toast('已经是第一页了哦~')
          return 
        } else {
          this.page--
        }
    }

    /**
     * 
     * 下一页
     * @private
     * @returns void
     */
     private async nextPage (questionItem): Promise<void>
    {
      if(this.page == this.questionList.subItems.length - 1) {
          Toast('已经是最后一页了哦~')
          return 
        } else {
          // 判断选项是否是必填
          let subjectType = questionItem.subjectType
          
          if(questionItem.isRequired) {
            if(subjectType == 4 && !questionItem.answerVal) {
              Toast('此题为必填项')
              return
            } else if(subjectType == 2 && !questionItem.answerSelectKeys.length) {
              Toast('此题为必填项')
              return
            } else if(subjectType == 0 && !questionItem.answerSelectKey) {
              Toast('此题为必填项')
              return
            }
          } 

            this.page++
            let isFinishing = false
            if(this.page == this.questionList.subItems.length - 1) {
              isFinishing = true
            }

              this.questionData.isFinishing = isFinishing
              this.questionData.answeringNo = questionItem.no
              this.questionData.questionnaire = this.questionList

             try {
              let {content:result} = await ReportService.instance.getAnswering(this.questionData)
              this.questionList.recordId = result.data
              this.questionData.id = result.data
             } catch (error) {
              Toast(error)
             }
          }
        
     }


    /**
     * 
     * 问卷评估
     * @private
     * @returns void
     */
     private async questionValuation(): Promise<void>
    {
        try {
          this.loading = true
          await ReportService.instance.questioEvaluating(this.questionData)
          setTimeout(()=> {
            this.loading = false
            this.$router.replace({name:'aicustomized'})
          },2000)

        } catch (error) {
          Toast(error)
          this.loading = false
        }
    }


}
