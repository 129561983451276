
import { Component, Vue, Watch } from "vue-property-decorator";
import { Toast,Dialog } from "vant";
import globalConfig from "src/config/index";
import familylist from "src/components/family-list/family-list.vue"
import { nextTick } from "process";
import { ReportService } from "src/services";
import router from "src/router";

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component({
    components:
    {
        familylist
    }
})
export default class OfteUser extends Vue
{
    /**
     * 选择人员
     * @private
     * @returns any
     */
     private familyUserName: String = "";


     /**
     * 选择人员弹窗
     * @private
     * @returns any
     */
     private showUserPopup: boolean = false;


     /**
     * 当前保存人员的id
     * @private
     * @returns any
     */
     private employerMemberId: any = 0;


    /**
     * 数据模型
     * @private
     * @returns any
     */
     private formData: any = {
        employerMemberId:null, // 受检人id
        reportId:null,
        reportName:""
    };

    /**
     * 确定选中的体检人
     * @private
     * @returns void
     */
     private onConfirmUser(data: any,bol): void
    {
        this.$store.dispatch("setSelectUser", data);
        this.formData.employerMemberId = data.employerMemberId
        this.familyUserName = data.memberName
        this.showUserPopup = false
        if(!bol) {
            this.formData.reportId = null
            this.formData.reportName = ""
        }
        
    }


    private changeUserPopup(data) {
        this.showUserPopup = false
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
     protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    protected created()
    {
        let selectUser = JSON.parse(localStorage.getItem("selectUser"));
        if(selectUser) {
            this.formData.employerMemberId = selectUser.employerMemberId;
            this.familyUserName = selectUser.memberName || selectUser.name;
        } else {
            this.formData.employerMemberId = this.userInfo.userId;
            this.familyUserName = this.userInfo.nickName;
        }

        if (this.$route.query)
        {
            this.formData.reportId = this.$route.query.reportId
            this.formData.reportName = this.$route.query.name

            if(this.$route.query.employerMemberId) {
                this.employerMemberId = this.$route.query.employerMemberId as any
            }
        }
    }


    /**
     * 切换报告
     * @private
     * @returns void
     */
     private async replaceReport(data: any)
    {
       // 校验名称是否有输入
       if(!this.familyUserName) {
            Toast('请选择体检人')
            return
       }

       // 校验是否通过验证
       let {content:result} = await ReportService.instance.checkIdentity()
        if(!result.data.checked) {
            this.$router.push({ name: "identityVerify",query:{id:this.formData.employerMemberId, sourcePage: "relatedReport"} });
        } else {
            if(this.formData.reportId) {
                this.$router.push({name:'replacementReport',query:{employerMemberId:this.formData.employerMemberId,id:this.formData.reportId, sourcePage: "relatedReport"}})
            } else {
                this.$router.push({name:'replacementReport',query:{employerMemberId:this.formData.employerMemberId, sourcePage: "relatedReport"}})
            }
        }

    }

    private nextPage () {
        // 校验名称是否有输入
       if(!this.familyUserName) {
            Toast('请选择体检人')
            return
       }

       this.$router.replace({name:"questionfill",query:
            {
                employerMemberId:this.formData.employerMemberId,
                reportId:this.formData.reportId
             }
        })
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }



}
