
import { Component, Vue } from "vue-property-decorator";
import { Toast,Dialog } from "vant";
import globalConfig from "src/config/index";
import { ReportService } from "src/services";
import customizeHome from "./sub/customize-home.vue"
import uProgress from "./sub/progress.vue"
import historyRecord from "./sub/history-record.vue"

@Component({
  components:
  {
      customizeHome,
      uProgress,
      historyRecord,
  }
})
export default class OfteUser extends Vue
{
  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any
  {
      let appid = localStorage.getItem("appid");

      let employerConfig = globalConfig.employerConfig[appid];

      return employerConfig;
  }

  /**
   * 获取id
   * @private
   * @returns void
   */
   private get id (): any
  {
      return this.$route.query.id || "";
  }

  /**
   * 问卷解析结果
   * @private
   * @returns void
   */
  private analyzeResult = {
      id: 0,
      status: 0,
      statusStr: "",
      isSelfProcing: false,
      hasNoneQuestionnaire: false,
      hasProcingQuestionnaire: false,
      evaluatedTime: "",
      memberId: 0,
      employerMemberId: 0,
      avatar: "",
      memberName: "",
      gender: 0,
      genderStr: "",
      memberType: 1,
      memberTypeStr: "",
      marriageStatus: 0,
      marriageStatusStr: "",
      age: 0,
      bodySyetems: []
  };

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
   protected created()
  {
    if(this.id) {
        this.getRecordAnalyze(this.id)
    } else {
        this.getRecordAnalyze(null)
    }
  }



  /**
   * 人体数据
   * @private
   * @returns any
   */
 private humanData : any = {
      reproductiveSystem: {},
      digestiveSystem: {},
      endocrineSystem: {},
      circulatorySystem: {},
      nervousSystem: {},
      urinarySystem: {},
      motionSystem: {},
      respiratorySystem: {},
      otherSystems: {},
  }

  /**
   * 异常指标数量
   * @private
   * @returns any
   */
  private humanDataCount = {
      reproductiveCount: null,
      digestiveCount: null,
      endocrineCount: null,
      circulatoryCount: null,
      nervousCount: null,
      urinaryCount: null,
      motionCount: null,
      respiratoryCount: null,
      otherCount: null,
  }

  private highlight = 3

  /**
 * 风险列表
 * @private
 * @returns any
 */
 private abnormalList = []

  /**
  * 系统名称
  * @private
  * @returns any
  */
  private bodySystemName = ""

   /**
   * 人体系统
   * @private
   * @returns any
   */
  private human : any = [
      "生殖系统",
      "消化系统",
      "内分泌系统",
      "循环系统",
      "神经系统",
      "泌尿系统",
      "运动系统",
      "呼吸系统",
      "其他",
  ]   

  /**
   * 是否有问卷记录
   * @protected
   * @returns void
   */
   protected async getRecordAnalyze(id)
  {
      try {
         let {content:result} = await ReportService.instance.getRecordAnalyze(id)
          this.analyzeResult = result.data

          let arr = [];
          this.human.forEach((item) => {
                  this.analyzeResult.bodySyetems.filter((staticItem) => {
                  if (item == staticItem.bodySystemName) {
                      arr.push(staticItem);
                  }
              });
          });

          // 系统
          (this.humanData.reproductiveSystem) = arr.find(
              (item) => item.bodySystemName == "生殖系统"
          );
          this.humanData.digestiveSystem = arr.find(
              (item) => item.bodySystemName == "消化系统"
          );
          this.humanData.endocrineSystem = arr.find(
              (item) => item.bodySystemName == "内分泌系统"
          );
          this.humanData.circulatorySystem = arr.find(
              (item) => item.bodySystemName == "循环系统"
          );
          this.humanData.nervousSystem = arr.find(
              (item) => item.bodySystemName == "神经系统"
          );
          this.humanData.urinarySystem = arr.find(
              (item) => item.bodySystemName == "泌尿系统"
          );
          this.humanData.motionSystem = arr.find(
              (item) => item.bodySystemName == "运动系统"
          );
          this.humanData.respiratorySystem = arr.find(
              (item) => item.bodySystemName == "呼吸系统"
          );
          this.humanData.otherSystems = arr.find(
              (item) => item.bodySystemName == "其他"
          );

          this.showAbnormal()

      } catch (error) {
          
      }
  }

  protected refresh() {
    this.getRecordAnalyze(undefined)
  }

  /**
   * 异常数据
   * @protected
   * @returns void
   */
  protected async showAbnormal() {
      for (let key in this.humanData) {
      if(this.humanData[key] && this.humanData[key].risks.length) {
        switch (this.humanData[key].bodySystemName) {
          case '生殖系统':
          this.highlight = 1
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          case '泌尿系统':
          this.highlight = 2
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          case '消化系统':
          this.highlight = 3
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          case '运动系统':
          this.highlight = 4
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          case '内分泌系统':
          this.highlight = 5
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          case '呼吸系统':
          this.highlight = 6
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          case '循环系统':
          this.highlight = 7
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          case '其他':
          this.highlight = 8
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          case '神经系统':
          this.highlight = 9
          this.systemChange(this.highlight,undefined)
          this.physicalReportCount()
          return;
          default:
          return;
        }
        
      } else {
        this.highlight = 3
        this.systemChange(this.highlight,undefined)
        this.physicalReportCount()
      }
    }
  }


   /**
   * 切换图标展示
   * @private
   * @returns any
   */
 protected systemChange(val,click)
  {
      this.highlight = val
      switch (val) {
      case 1:
        this.abnormalList =
          this.humanData.reproductiveSystem &&
          this.humanData.reproductiveSystem.risks;
          // 当前系统名称
          this.bodySystemName = "生殖系统"
        break;
      case 2:
        this.abnormalList =
          this.humanData.urinarySystem && this.humanData.urinarySystem.risks;
          // 当前系统名称
          this.bodySystemName = "泌尿系统"
        break;
      case 3:
        this.abnormalList =
          this.humanData.digestiveSystem &&
          this.humanData.digestiveSystem.risks;
          // 当前系统名称
          this.bodySystemName = "消化系统"
        break;
      case 4:
        this.abnormalList =
          this.humanData.motionSystem && this.humanData.motionSystem.risks;
          // 当前系统名称
          this.bodySystemName = "运动系统"
        break;
      case 5:
        this.abnormalList =
          this.humanData.endocrineSystem &&
          this.humanData.endocrineSystem.risks;
          // 当前系统名称
          this.bodySystemName = "内分泌系统"
        break;
      case 6:
        this.abnormalList =
          this.humanData.respiratorySystem &&
          this.humanData.respiratorySystem.risks;
          // 当前系统名称
          this.bodySystemName = "呼吸系统"
        break;
      case 7:
        this.abnormalList =
          this.humanData.circulatorySystem &&
          this.humanData.circulatorySystem.risks;
          // 当前系统名称
          this.bodySystemName = "循环系统"
        break;
      case 8:
        this.abnormalList =
          this.humanData.otherSystems && this.humanData.otherSystems.risks;
          // 当前系统名称
          this.bodySystemName = "其他系统"
        break;
      case 9:
        this.abnormalList =
          this.humanData.nervousSystem && this.humanData.nervousSystem.risks;
          // 当前系统名称
          this.bodySystemName = "神经系统"
        break;
      default:
        break;
      }
 }


  physicalReportCount() {
    // 数量
    this.humanDataCount.reproductiveCount =
      this.humanData.reproductiveSystem &&
      this.humanData.reproductiveSystem.riskCount;
    this.humanDataCount.digestiveCount =
      this.humanData.digestiveSystem && this.humanData.digestiveSystem.riskCount;
    this.humanDataCount.endocrineCount =
      this.humanData.endocrineSystem && this.humanData.endocrineSystem.riskCount;
    this.humanDataCount.circulatoryCount =
      this.humanData.circulatorySystem &&
      this.humanData.circulatorySystem.riskCount;
    this.humanDataCount.nervousCount =
      this.humanData.nervousSystem && this.humanData.nervousSystem.riskCount;
    this.humanDataCount.urinaryCount =
      this.humanData.urinarySystem && this.humanData.urinarySystem.riskCount;
    this.humanDataCount.motionCount =
      this.humanData.motionSystem && this.humanData.motionSystem.riskCount;
    this.humanDataCount.respiratoryCount =
      this.humanData.respiratorySystem &&
      this.humanData.respiratorySystem.riskCount;
    this.humanDataCount.otherCount =
      this.humanData.otherSystems && this.humanData.otherSystems.riskCount;
  }



}
